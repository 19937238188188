.textarea {
  margin-bottom: 10px;
  font-size: 13px;
}

.text {
  margin-left: 10px;
}

.about {
  margin-bottom: 5px;
  margin-top: 5px;
}

.padding {
  padding-bottom: 60px;
  margin-bottom: 60px;
}
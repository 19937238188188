.container {
  display: flex;
  justify-content: space-around;
}

.commits { 
  margin-top: 30px;
}

.icon {
  margin-right: 4px;
}
.vert {
  position: relative !important;
  right: 50px;
}

.uppercase {
  text-transform: uppercase;
  font-family: Dunkin;
}

.dashboard {
  color: #FFFFFF;
  position: relative !important;
  right: 50px;
}

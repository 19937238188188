.container {
  display: flex;
  justify-content: center;
}

.text {
  color: #003E6D;
  text-align: center;
}

.border {
  border-radius: 50px;
  border-width: 2.5px !important;
  padding: 15px;
  height: 100%;
}
.size {
  width: 260px;
}

.text  {
  margin-bottom: 3px;
  font-size: 13px;
}

.padding {
  padding-top: 10px;
}
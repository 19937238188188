body {
  font-family: Varela !important;
  height: 100vh;
  transition: 0.3s ease-in;
}

.navbar {
  transition: 0.45s ease-in;
}

.page-item.active .page-link {
  background-color: #00ccc6 !important;
  border-color: #00ccc6 !important;
}

.page-link:hover {
  transition: ease-in 0.4s;
  transition-delay: 0.03s;
  background-color: #4f5b62 !important;
  border-color: #4f5b62 !important;
}

body .bg-color {
  background: #ffb208;
}

/** Background Colors and colors */
.bg-red {
  background-color: #ea4335 !important;
  border-color: #ea4335 !important;
  transition: all 0.2s;
}

.bg-red:hover {
  background-color: #cc3c30 !important;
}

.bg-gray {
  background-color: #2e353d !important;
  border-color: #2e353d !important;
}

.orange-primary {
  background-color: #ffb300;
  border-color: #ffb300;
  transition: all 0.2s;
}

.orange-primary:hover {
  background-color: rgb(216, 134, 11);
  border-color: rgb(216, 134, 11);
}

.orange-primary:disabled {
  color: #fff;
  background-color: rgb(216, 134, 11);
  border-color: rgb(216, 134, 11);
}

.orange-primary:active {
  color: #fff;
  background-color: #cc9516 !important;
  border-color: #cc9516 !important;
}

.link {
  color: #4f5b62 !important;
  transition: ease-in-out 0.4s;
  text-decoration: none !important;
}

.link:hover {
  color: #f9a825 !important;
}

.text-gray {
  color: #4f5b62 !important;
}

.text-orange {
  color: #f9a825 !important;
}

.text-turquoise {
  color: #00ccc6 !important;
}

.text-dark {
  color: #3c3c3c !important;
}

.text-blue {
  color: #003e6d !important;
}

/** Icon Styles */
.icon {
  position: relative;
}

.icon-container-enabled {
  transition: ease-in 0.4s;
  color: #ea4335;
}

.icon-container-disabled {
  transition: ease-in 0.4s;
}

.icon-container-enabled:hover {
  color: #333;
  cursor: pointer;
}

.icon-test {
  color: #f9a825 !important;
  transition: all ease-in 0.4s;
}

.icon-test:hover {
  color: #4f5b62 !important;
  cursor: pointer;
}

.float-left {
  float: 'left';
}

.float-right {
  float: 'right';
}

.font-small {
  font-size: 12px !important;
}

.dropdown-item {
  border-style: none !important;
  border-color: red;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #003e6d !important;
}

.hovered:hover {
  cursor: pointer;
}

.material-shadow {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 2px 2.5px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.fs-1 {
  font-size: 22px !important;
  bottom: 8px;
  transition: ease-in 0.4s;
}

.fs-1:hover {
  font-size: 22px !important;
  bottom: 8px;
  cursor: pointer;
  color: red !important;
}

th {
  transition: ease-in-out 0.2s;
}

.border-style {
  border: 2px solid #e5e5e5 !important;
  border-radius: 16px;
  display: block;
  width: 400px;
}

.page-item.active .page-link {
  background-color: #ffb300;
  border-color: #ffb300;
}

.page-link:hover {
  transition: ease-in 0.4s;
  transition-delay: 0.03s;
  background-color: #4f5b62 !important;
  border-color: #4f5b62 !important;
}

.progress {
  height: 1.5rem !important;
  border-radius: 50px !important;
}

.progress-bar {
  background-color: #00ccc6 !important;
  border-radius: 50px !important;
}

.progress-bar-dark {
  background-color: #ffb300 !important;
}

.text-success {
  color: #58a700 !important;
}

.text-danger {
  color: #ea2b2b !important;
}

.table-style-component thead tr th {
  color: white;
  background: #adaca8;
}

.select-css {
  font-size: 13.5px;
  font-weight: 400;
  line-height: 1 !important;
  padding: 0.6em 1.4em 0.5em 0.8em;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #d0d0d0;
  appearance: none;
}

.select-css::-ms-expand {
  display: none;
}

.select-css:hover {
  border-color: #888;
}

.select-css:focus {
  outline: none;
  border: 1px solid #00ccc6;
}

.select-css option {
  color: #003e6d;
  font-family: Monserrat-Light;
}

/* Support for rtl text, explicit support for Arabic and Hebrew */
*[dir='rtl'] .select-css,
:root:lang(ar) .select-css,
:root:lang(iw) .select-css {
  background-position: left 0.7em top 50%, 0 0;
  padding: 0.6em 0.8em 0.5em 1.4em;
}

/* Disabled styles */
.select-css:disabled,
.select-css[aria-disabled='true'] {
  color: red !important;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
    linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
}
.select-css:disabled:hover,
.select-css[aria-disabled='true'] {
  border-color: #aaa;
}

.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #fcbe29;
}

.tooltip-qm {
  float: left;
  margin: -2px 0px 3px 4px;
  font-size: 14px;
  font-weight: bold;
}

.tooltip-inner {
  background: #4f5b62;
  font-size: 14.5px;
  color: #ffffff;
}

.tooltip.show {
  opacity: 0.9;
}

input[type='range'][orient='vertical'] {
  writing-mode: bt-lr; /* IE */
  -webkit-appearance: slider-vertical; /* WebKit */
  width: 8px;
  height: 100%;
  background-color: aqua;
}

input[type='range']::-webkit-slider-runnable-track {
  height: 10px;
  -webkit-appearance: none;
  color: #13bba4;
  margin-top: -1px;
}

input[type='range']::-webkit-slider-thumb {
  width: 10px;
  -webkit-appearance: none;
  height: 10px;
  cursor: ew-resize;
  background-color: #434343;
}

.volume-set {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: #fff;
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.volume-set::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #ffb300;
  cursor: pointer;
}

.volume-set::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #ffb300;
  cursor: pointer;
}

[type='radio']:checked + label,
[type='radio']:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
[type='radio']:checked + label:before,
[type='radio']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
[type='radio']:checked + label:after,
[type='radio']:not(:checked) + label:after {
  content: '';
  width: 9px;
  height: 9px;
  background: #b3d7ff;
  position: absolute;
  top: 4.5px;
  left: 5px;
  border-radius: 100%;
  transition: all 0.2s ease;
}

[type='radio']:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type='radio']:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.lock-opacity {
  opacity: 0.7;
  color: #dee2e6;
}

.nav-tabs .nav-link {
  background-color: transparent !important;
  color: #888 !important;
}

.report-icon:hover {
  transition: ease-in 0.6s;
  color: #cc3c30 !important;
  cursor: pointer;
}

.report-icon {
  transition: ease-in 0.6s;
}

option:disabled {
  color: #ea4335 !important;
}

.error-overlay {
  width: 100%;
  height: 100%;
  z-index: 30;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(240, 0, 0, 0.3);
  transition: all;
  transition-duration: 2s;
  transition-delay: 0.4s;
}

.success-overlay {
  width: 100%;
  height: 100%;
  z-index: 30;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 240, 0, 0.3);
  transition: all;
  transition-duration: 2s;
  transition-delay: 0.4s;
}

.text-overlay:hover {
  color: #63a4ea !important;
  transition: ease-in 0.2s;
}

.text-overlay:active {
  color: #7f77f3 !important;
  transition: all 0.3s;
}

.reading-icon {
  position: relative;
  bottom: 5px;
}

.help {
  position: relative;
  bottom: 10px;
}

video::-webkit-media-controls {
  display: none !important;
}

.rcw-conversation-container .rcw-header {
  background-color: #35cce6;
  border-radius: 10px 10px 0 0;
  color: #fff;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 5px 0 5px !important;
}

.rcw-messages-container {
  background-color: #fff;
  height: 50vh;
  max-height: 380px !important;
  overflow-y: scroll;
  padding-top: 10px;
  -webkit-overflow-scrolling: touch;
}

.modal-backdrop.show {
  z-index: 1;
  opacity: 0.5;
}

li {
  list-style-type: none;
}

.carousel-item-view {
  padding: 20px 0 20px 40px;
}

input::-webkit-input-placeholder {
  font-size: 11px;
  line-height: 3;
}

li {
  list-style-type: none;
}

@font-face {
  font-family: Varela;
  src: url('../fonts/valera-round/VarelaRound-Regular.ttf');
}

@font-face {
  font-family: Dunkin;
  src: url('../fonts/dunkin/Dunkin.otf');
}

@font-face {
  font-family: Nunito;
  src: url('../fonts/nunito-black/Nunito-Black.ttf');
}

@font-face {
  font-family: Monserrat;
  src: url('../fonts/monserrat/Montserrat-Black.ttf');
}

@font-face {
  font-family: Monserrat-Medium;
  src: url('../fonts/monserrat/Montserrat-Medium.ttf');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Monserrat-Bold;
  src: url('../fonts/monserrat/Montserrat-Bold.ttf');
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Monserrat-Light;
  src: url('../fonts/monserrat/Montserrat-Light.ttf');
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #dbd9cf;
  border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(156, 156, 156);
}

.dunkin {
  font-family: Dunkin;
}

.monserrat-light {
  font-family: Monserrat-Light;
}

.MuiStepIcon-root.MuiStepIcon-completed {
  color: #00ccc6 !important;
}

.MuiStepIcon-root.MuiStepIcon-active {
  color: #00ccc6 !important;
}


.pro-sidebar-inner {
  background-color: #ffffff !important;
  border-right: 1px solid rgba(173, 173, 173, 0.2) !important;
}

.pro-sidebar > .pro-sidebar-inner > img.sidebar-bg  {
  height: 65% !important;
  bottom: 0px !important;
  top:initial !important;
}

.pro-icon-wrapper {
  background-color: #003e6d !important;
  color: #ffffff;
}

.pro-inner-item {
  color: #6c757d !important;
  transition: ease-in-out 0.5s;
}

.pro-inner-item:hover {
  color: #003e6d !important;
  transition: ease-in-out 0.5s;
}

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
  background-color: transparent !important;
}
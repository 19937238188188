#root {
  height: 100%;
}

.scrollbar {
  overflow-y: scroll;
}

.force-overflow {
  min-height: 600px;
}

.scrollbar::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: #2e353d;
  border-radius: 5px;
}

.scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.spinner-position div {
  margin: auto;
}

.spinner-position div svg {
  width: 100%;
}

.title {
  font-family: Dunkin;
  font-weight: normal;
  text-transform: uppercase;

  h6 {
    letter-spacing: 0.2em;
  }

  span {
    font-family: Monserrat;
    text-transform: lowercase;
  }

  li {
    font-family: Monserrat-Light;
    text-transform: none;
    font-size: 0.9rem !important;
    margin-bottom: 10px;
  }

  button {
    font-family: Monserrat-Bold;
    text-transform: uppercase;
    font-size: 1rem;
  }

  ul {
    font-size: 0.9rem !important;
  }
}

.box {
  padding: 45px;
  border-radius: 1rem !important;
  margin-bottom: 15px;
}

.item-1 {
  h6 {
    color: #ff8c8c !important;
  }

  h3 {
    color: #ff3f3f !important;
  }

  span {
    color: #ff8c8c !important;
  }

  button {
    background-color: #ff3f3f;
    border-color: #ff3f3f;
  }

  div[about] {
    background-color: #ff8c8c !important;
    width: 5rem;
    height: 6px;
    border-radius: 1rem;
    position: relative;
    top: 30px;
    img {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      top: -43px;
    }
  }
}

.item-2 {
  h6 {
    color: #0069ba !important;
  }

  h3 {
    color: #003e6d !important;
  }

  span {
    color: #0069ba !important;
  }

  button {
    background-color: #003e6d;
    border-color: #003e6d;
  }

  div[about] {
    background-color: #0069ba !important;
    width: 5rem;
    height: 6px;
    border-radius: 1rem;
    position: relative;
    top: 30px;
    img {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      top: -40px;
    }
  }
}

.item-3 {
  h6 {
    color: #40d5d0 !important;
  }

  h3 {
    color: #00ccc6 !important;
  }

  span {
    color: #40d5d0 !important;
  }

  button {
    background-color: #00ccc6;
    border-color: #00ccc6;
  }

  div[about] {
    background-color: #40d5d0 !important;
    width: 5rem;
    height: 6px;
    border-radius: 1rem;
    position: relative;
    top: 30px;
    img {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      top: -43px;
    }
  }
}

.cardheader {
  font-family: Dunkin;
  color: #ffffff;
  text-align: center;
  background-color: #003e6d !important;
  border-radius: 0 !important;
  border-top-left-radius: 2.5px !important;
  border-top-right-radius: 2.5px !important;
}

.data {
  th,
  td {
    font-family: Monserrat-Light;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: center;
  }
}

.theader {
  color: #003e6d;
  font-size: 0.68rem;
  font-weight: bold;
  font-family: Monserrat-Bold !important;
}

.mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  font-size: 25px;
  top: 80px;
  padding: 10px;
}

.vertical {
  padding: 30px 10px;
  font-size: 18px;
  border-top: 1px solid #a9a9a9;
  font-weight: 200;
  display: flex;
  justify-content: space-between;
  font-family: Monserrat-Light;
  letter-spacing: 0.3px;
  color: #003e6d;
  align-items: center;
  @media (min-width: 800px) {
    font-size: 13px;
    position: sticky;
    left: 40px;
  }
}


.root {
  padding-bottom: 40px;
}
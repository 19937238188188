.container {
  padding: 20px;
}

.button {
  display: flex;
  justify-content: space-evenly;
  margin-top: 25px;
  color: #FFFFFF;
}

.single {
  display: flex;
  margin-top: 20px;
  margin-left: 10px;
}

.details {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.icon {
  position: relative;
  bottom: 1.2px;
}

.margin {
  margin-right: 5px;
  margin-left: 5px;
}

.title {
  margin-bottom: 10px;
  margin-right: 2.5px;
}

.btnC{
  margin-top: 10px;
}

.required {
  display: flex;
}

.about {
  justify-content: center;
  align-items: center;
  align-self: center;
}

.radio {
 text-align: left !important;
}

.input {
  position: relative;
  top: 2px;
  margin-right: 10px;
}

.image {
  position: relative;
  bottom: 2.5px;
  margin-left: 10px;
}

.table th {
  padding: .75rem;
  vertical-align: middle !important;
  border-top: 1px solid #dee2e6;
}

.management {
  thead {
    tr{
      th {
        background-color: #003e6d !important;
        color: #FFFFFF;
        padding: 8px 0px;

        small {
          color: #FFFFFF !important;
        }
      }
    }
  }   
}
.setup {
  max-width: 380px;
  width: 100%;
  border-radius: 20px;
  border: none;
  box-shadow: 0px 7px 10px 5px #0000001c;
}

.checkout {
  border: none;
  border-radius: 50px;
  padding: 5px 10px;
  background-color: #003e6d !important;
}

.cancel {
  border: none;
  border-radius: 50px;
  padding: 5px 10px;
  margin-right: 10px;
}
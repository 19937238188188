.container {
  input {
    border-radius: 50px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.margin {
  margin-top: 20px;
}

.col {
  margin-bottom: 15px;
}

.flex {
  display: flex;
  justify-content: center;
}

.card {
  background-color: #ffffff;
  border-radius: 50px;
  border: none;
  box-shadow: 0px 3px 10px 2px #00000036;
  color: #003e6d;
  position: relative;
  height: 100%;
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #ffb208;
  background-image: url("https://dkmwdxc6g4lk7.cloudfront.net/assets/svg/background.svg");
  position: relative;
  text-align: center;
  font-family: Monserrat-Light;
  padding: 15px;
  
  button {
    font-weight: bold;
    padding: 5px 20px;
  }

  h2 {
    font-family: Dunkin;
  }

  .access {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    color: #ffffff;
    background: #005081;
    padding: 50px 80px;

    form {
      padding: 20px 0px;
    }

    @media (max-width: 768px){
      padding: 50px 44px;
    }

    @media (max-width: 575.98px) {
      border-radius: 8px;
      padding: 50px 33px;
    }
  }

  .quick {
    background: #ffffff;
    color: #005081;
    padding: 50px 80px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    display: flex;
    flex-direction: column;
    position: relative;
    img[about] {
      position: absolute;
      bottom: 0;
      right: 0;
    }

    @media (max-width: 575.98px) {
      display: none;
    }
    @media (max-width: 768px){
      padding: 50px 36px;
    }
  }

  .shadow {
    display: flex;
  }

  .google {
    margin-top: 25px;
    margin-bottom: 25px;
    background-color: transparent;
    border: 2px solid #005081;
    color: #005081;
    @media (max-width: 575.98px) {
      border: 2px solid #ffffff;
    color: #ffffff;
    }
  }

  .signup {
    color: white;
    margin-top: 10px !important;
    border: none;
    background-color: transparent;
    letter-spacing: 1px;
    font-weight: bold;
  }

  .margin {
    margin-top: 15px;
  }
}

.link {
  margin-top: 10px;
  color: #ffffff;
}

.container {
  display: grid;
  grid-template-columns: repeat(3, 2fr);
  grid-template-rows: 2fr;
  grid-column-gap: 30px;
  grid-row-gap: 0px;
  padding: 0px 20px;
  
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    button[about="unblock"] {
      display: none;
    }
  }

  @media (max-width: 575.98px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    div[about] {
      button {
        display: none;
      }
    }
  }
}

.round {
  border-radius: 50px;
}

.center {
  text-align: center;
}

.item-1 {
  grid-area: 1 / 1 / 2 / 2;

  p {
    font-family: Monserrat-Light;
  }

  button {
    background-color:  #003E6D!important;
    border-color:  #003E6D;
    color: #FFFFFF !important; 
    font-family: Monserrat-Light;
    font-weight: bold;
    margin-bottom: 5px;
  }

  @media (max-width: 575.98px) {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 991.98px) {
    display: none;
  }

}

.item-2 {
  grid-area: 1 / 2 / 2 / 3;

  div[about] {
    background: #00ccc6;
    border-radius: 35px;
    padding: 4px 15px;
    color: #fff !important;

    p {
      font-family: Monserrat-Light;
    }

    h5 {
      color: #fff !important;
    }

    button {
      background-color: #FFFFFF !important;
      border-color: #FFFFFF;
      color: #003E6D !important; 
      font-family: Monserrat-Light;
      font-weight: bold;
      margin-bottom: 5px;
    }
  }
}

.item-3 {
  grid-area: 1 / 3 / 2 / 4;

  p {
    font-family: Monserrat-Light;
  }

  button {
    background-color:  #003E6D!important;
    border-color:  #003E6D;
    color: #FFFFFF !important; 
    font-family: Monserrat-Light;
    font-weight: bold;
    margin-bottom: 5px;
  }

  @media (max-width: 575.98px) {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 991.98px) {
    display: none;
  }
}

.title h3 {
  font-family: Dunkin;
  text-transform: uppercase;
}

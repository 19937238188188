.circle {
  width: 300px;
  height: 300px;
  border-radius: 200px;
  border: 1.5px solid #ccc;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  small {
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 5px;
  }

  button:focus {
    box-shadow: none !important;
  }
}

.padding {
  padding-left: 10px;
}

.content {
  padding-bottom: 150px;
}

.image {
  margin-bottom: 10px;
}
.audio-player {
  width: 400px;
  min-width: 300px;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 50px;
  -webkit-user-select: none;
  user-select: none;
  background-color: #013f6e;
  padding: 0;
}

.slider {
  flex-grow: 1;
  background-color: #ffffff;
  cursor: pointer;
  position: relative;
}

.slider .gap-progress {
  background-color: #ffffff;
  border-radius: inherit;
  position: absolute;
  pointer-events: none;
}

.slider .gap-progress .pin {
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background-color: #00ccc6;
  position: absolute;
  pointer-events: all;
  box-shadow: 0 1px 1px 0 gray;
}

.slider .gap-progress .pin::after {
  content: '';
  display: block;
  background: transparent;
  width: 200%;
  height: 200%;
  margin-left: -50%;
  margin-top: -50%;
  border-radius: 50%;
}

.controls {
  font-size: 16px;
  line-height: 18px;
  color: #fff;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

.controls .control-slider {
  margin-left: 16px;
  margin-right: 16px;
  border-radius: 50px;
  height: 10px;
}

.controls .control-slider .control-progress {
  width: 0;
  height: 100%;
}

.controls .control-slider .control-progress .pin-progress {
  right: -8px;
  top: -4px;
}

.controls span {
  cursor: default;
}

.volume {
  position: relative;
}
.volume .volume-btn {
  cursor: pointer;
}
.volume .volume-btn.open path {
  fill: green;
}
.volume .volume-controls {
  width: 25px;
  height: 135px;
  background-color: #2e353d;
  border-radius: 7px;
  position: absolute;
  left: 15px;
  bottom: 52px;
  flex-direction: column;
  align-items: center;
  display: flex;
  z-index: 2;
}
.volume .volume-controls.hidden {
  display: none;
}
.volume .volume-ontrols.top {
  bottom: 52px;
  left: -3px;
}
.volume .volume-controls.middle {
  bottom: -54px;
  left: 54px;
}
.volume .volume-controls.bottom {
  bottom: -164px;
  left: -3px;
}
.volume .volume-controls .volume-slider {
  margin-top: 12px;
  margin-bottom: 12px;
  width: 6px;
  border-radius: 3px;
}
.volume .volume-controls .volume-slider .volume-progress {
  bottom: 0;
  height: 100%;
  width: 6px;
}
.volume .volume-controls .volume-slider .volume-progress .volume-pin {
  left: -5px;
  bottom: 102px;
}

.play-btn .btn-link,
.volume-btn {
  color: #fff;
}

.play-btn {
  padding: 0px;
}

@media (max-width: 768px) {
  .audio-player {
    width: 100%;
  }
}



$master-color: #E8B47B ;
$diamond-color: #7FC4CB;
$grand-color: #ffb300;

@mixin check {
  border-radius: 25px;
  color: white;
  font-size: 11px;
  padding: 3px;
  margin-right: 1rem;
}

@mixin header{
  height: 150px;
  width: 100%;
  background-color: $diamond-color; /* For browsers that do not support gradients */
  color: white;
  text-align: center;
  padding: 0.5rem;
  border-radius: 12px 12px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.price-tables{

  li{
    list-style: none;
  }

  a{
    color:white;
  }

  .fa-info-circle{
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 15px;
  }

  .pricing{
    &-header {
      &-go{
        @include header;
        background-image: linear-gradient(#E32332, #E8B47B); 
      }
      &-diamond{
          @include header;
          background-image: linear-gradient(#4091E3, #92CDC7); 
      }
      &-grand{
          @include header;
          background-image: linear-gradient(#615198, #9D4CA0); 
      }
    }
      &-table {
        &-middle {
          transform: scale(1.03);
          z-index: 15;
          @media(min-width: 1024px){
            margin: 0px 3px;
          }
        }
        min-width: 115px;
        text-align: center;
        max-width: 300px;
        height: 100%;
        display: flex;
        flex-flow: column wrap;
        box-shadow: 0px 2px 10px 0px rgba(50, 50, 50, 0.5);
        transform: scale(1);
        border:0;
        background-color: white;
        transition: 0.4s ease;
        z-index:13;
        border-radius: 20px;
            &:hover{
              transform: scale(1.1);
              z-index:95;
              @media only screen and (min-width: 641){  
                transform: scale(1);
              }
          }  
      }
      &-content {
        height: 200px;
        padding: 10px 10px;
        display: flex;
        align-items: center;
        @media(min-width: 641px) {
          padding: 20px 20px;
          height: 30vw;
        }
        @media(min-width: 800px) {
          height: 280px;
        }
        .fa-check{
          background-color: $master-color;
          @include check;
        }
        &-diamond{
          .fa-check{
            background-color: $diamond-color;
            @include check;
          }
        }
        &-grand{
          .fa-check{
              background-color: $grand-color;
              @include check;
          }
        }
      }
  }

  .pricingh {
    &-go{
      color: $master-color;
    }
    &-diamond{
      color: $grand-color;
    }
  }

  .bullet-container {
    padding: unset;
    margin: unset;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }

  .bullet-item{
    text-align: left;
    padding-bottom: 0.5rem;
    color: black;
    font-weight: 500;
    font-size: 11px;
    @media (min-width: 635px) {
      font-size: 10px;
    }
    @media (min-width: 641px) {
      font-size: 1.5vw;
    }
    @media (min-width: 800px) {
      font-size: 14px;
    }
    & > .highlight {
      font-weight: bold;
    }
  }

  .title{
    font-size: 25px;
    margin: unset;
    line-height: normal;
    &-card {
      font-size: 15px;
      text-transform: uppercase;
      margin: 5px;
          line-height: normal;
      @media (min-width: 641px) {
        font-size: 20px;
      }
      @media (min-width: 800px) {
        font-size: 25px;
      }
    }
    &-sub{
      font-size: 10px;
      margin-bottom: unset;
    }
  }

  .price-box {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .button{
    font-size: 9px;
    padding: 0.5rem;
    font-weight: 700;
    border-radius: 23px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    @media (min-width: 425px) {
      font-size: 11px;
    }
    @media (min-width: 641px) {
      padding: 0.5rem 1rem;
    }
    @media (min-width: 800px) {
      padding: 1rem 1.5rem;
    }
    &:active{
      border: none;
    }
  }

  .button--grand,  .button--go, .button--diamond {
    color: white;
    background-color: $grand-color;
    border: 2px solid white;
      &:hover{
        box-shadow: 0 0 1em 0.5em $grand-color;
        color:white;
      }
      &:focus{
        outline: none;
      }
  }
}
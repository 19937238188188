.margin {
  margin-bottom: 0px;
}

.separator {
  width: 5rem;
  height: 6px;
  border-radius: 1rem;
}

.container {
  display: flex;
  justify-content: space-between;
}
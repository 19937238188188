.view {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-top: 5px;
}

.loading {
  width: 100vw;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}

.withoutSpace {
  display: flex;
  width: 100%;
  justify-content: center;
  max-width: 1200px;
  @media (max-width: 576px) {
    padding: 15px 0 0 0;
  }

  & > .animated {
    width: 100%;
  }
}